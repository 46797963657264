import clsx from 'clsx';
import EmailIcon from 'components/Icons/Communication/EmailIcon';
import PhoneIcon from 'components/Icons/Communication/PhoneIcon';
import EtagLogo from 'components/Icons/Logo/EtagLogo';
import EtagLogoEng from 'components/Icons/Logo/EtagLogoEng';
import EtisLogoFullTextLongTextEst from 'components/Icons/Logo/EtisLogoFullTextLongText';
import EtisLogoFullTextLongTextEng from 'components/Icons/Logo/EtisLogoFullTextLongTextEng';
import PoiIcon from 'components/Icons/Map/PoiIcon';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { routeDataProtection, routeDevelopments, routeTermOfUse, routeUserManuals } from 'routes';

const Footer: FC = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = i18n.language;

  const footerLinks = (extraClass: string): JSX.Element => {
    return (
      <div className={clsx('col-md-4 font-14 font-nunito footer-links', extraClass)}>
        @2022{' '}
        <a href={`https://www.hm.ee/${currentLanguageCode}`}>{t('HaridusTeadusministeerium')}</a> |{' '}
        <a href={routeTermOfUse}>{t('TermOfUse')}</a> |{' '}
        <a href={routeDataProtection}>{t('DataProtection')}</a> |{' '}
        <a href={routeDevelopments}>{t('Features')}</a> |{' '}
        <a href={routeUserManuals}>{t('UserManuals')}</a>
      </div>
    );
  };

  return (
    <>
      <footer id="footer" className="container page-wrapper main-page">
        <div className="bg-blue500 d-flex first-wrapper">
          <div className="row">
            <div className="col-md-7 m-auto">
              {currentLanguageCode === 'et' ? (
                <EtisLogoFullTextLongTextEst />
              ) : (
                <EtisLogoFullTextLongTextEng />
              )}
              <p className="text-white font-16 p-t-24">{t('FooterText')}</p>
            </div>
            <div className="col-md-2" />
            <div className="col-md-3">
              <h4 className="text-white contact-block">
                <strong>{t('EtisSupportContact')}</strong>
              </h4>
              <address className="m-t-8">
                <p>
                  <PhoneIcon fill="#fffffF" width={16} height={16} />{' '}
                  <span className="p-l-16 font-14 text-white">
                    <a href="tel:+372 730 0373">+372 730 0373</a>
                  </span>
                </p>
                <p>
                  <EmailIcon fill="#fffffF" width={16} height={16} />{' '}
                  <span className="p-l-16 font-14 text-white">
                    <a href="mailto:etis@etag.ee">etis@etag.ee</a>
                  </span>
                </p>
                <p>
                  <PoiIcon fill="#fffffF" width={16} height={16} />{' '}
                  <span className="p-l-16 font-14 text-white">
                    <a href="https://www.google.ee/maps/place/Soola+8,+51013+Tartu/@58.3786827,26.7288147,17z/data=!3m1!4b1!4m2!3m1!1s0x46eb36dc2b22f33d:0x70b03a913dc581c2?hl=et">
                      Soola 8, Tartu 51013
                    </a>
                  </span>
                </p>
              </address>
            </div>
          </div>
        </div>
        <div className="d-flex second-wrapper">
          <div className="row">
            {footerLinks('d-none d-lg-block')}
            <div className="col-lg-2 col-md-12 text-lg-center text-md-left">
              <a
                href={`https://www.etag.ee/${currentLanguageCode === 'en' ? 'en/' : ''}`}
                aria-label="Estonian Research Council"
              >
                {currentLanguageCode === 'et' ? <EtagLogo /> : <EtagLogoEng />}
              </a>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-2 col-md-12 text-lg-center text-md-left">
              <a
                href={`https://www.hm.ee/${currentLanguageCode}`}
                aria-label="Ministry of Education and Research"
              >
                {currentLanguageCode === 'et' ? (
                  <img src="/images/Haridus-_ja_Teadusministeeriumi_logo_small.png" alt="" />
                ) : (
                  <img src="/images/Haridus-_ja_Teadusministeeriumi_logo_small_eng.png" alt="" />
                )}
              </a>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-2 col-md-12 text-lg-center text-md-left">
              <img src="/images/eu_small.jpg" alt="" />
            </div>
            {footerLinks('d-none show-only-mobile m-t-24')}
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
